.album-review__preview {
  width: auto;
  margin: 0;
  position: relative;
  display: block;
  height: 100%; }
  .album-review__preview__control {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1024;
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden; }
    .album-review__preview__control button {
      position: absolute;
      top: calc(50% - 50px);
      height: 100px;
      padding-top: 5px;
      text-align: center;
      background-color: rgba(41, 41, 41, 0.9);
      border: 0;
      opacity: 0.40;
      outline: 0;
      transition: all 0.25s ease;
      width: 35px;
      fill: #eee;
      cursor: pointer; }
      .album-review__preview__control button svg {
        width: 20px;
        height: 25px;
        display: inline-block; }
      .album-review__preview__control button:first-child {
        left: -30px;
        border-radius: 0px 3px 3px 0px; }
      .album-review__preview__control button:last-child {
        right: -30px;
        border-radius: 3px 0px 0px 3px; }
      .album-review__preview__control button:hover {
        opacity: 0.95; }
      .album-review__preview__control button:active {
        background-color: rgba(41, 41, 41, 0.98);
        opacity: 1; }
    .album-review__preview__control:hover button:first-child {
      left: 0px; }
    .album-review__preview__control:hover button:last-child {
      right: 0px; }
  .album-review__preview article section {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
  .album-review__preview__sheet {
    height: 100%;
    padding: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center; }
    .album-review__preview__sheet img {
      max-width: 100%;
      height: auto;
      width: auto;
      max-height: 100%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); }
