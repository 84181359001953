.swal-textarea {
  font-family: 'Open Sans', sans-serif; }
  .swal-textarea span {
    display: block;
    width: 100%;
    padding: 15px 0px;
    font-weight: 600;
    color: #333;
    text-align: left;
    font-size: 14px; }
  .swal-textarea textarea {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    padding: 10px;
    height: auto;
    min-height: 120px; }
