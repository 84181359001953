.album-review__sheet-actions {
  display: inline-block;
  padding: 0;
  width: 100%;
  margin: auto;
  position: relative;
  text-align: center; }
  .album-review__sheet-actions .action {
    display: inline-block;
    background-color: rgba(41, 41, 41, 0.975);
    font-family: 'Open Sans', sans-serif;
    padding: 0 15px;
    border: 0;
    height: 40px;
    text-align: center;
    line-height: 40px;
    vertical-align: middle;
    cursor: pointer;
    color: #aaa;
    transition: all 0.4s ease; }
    .album-review__sheet-actions .action svg {
      margin-top: 10px;
      margin-right: 5px;
      display: inline-block;
      float: left; }
    .album-review__sheet-actions .action--approve {
      color: #fff;
      background-color: transparent; }
      .album-review__sheet-actions .action--approve:hover {
        background-color: #05b563; }
    .album-review__sheet-actions .action--comment {
      color: #fff;
      background-color: transparent; }
      .album-review__sheet-actions .action--comment:hover {
        background-color: #d26f18; }
        .album-review__sheet-actions .action--comment:hover svg path {
          fill: #fff; }
      .album-review__sheet-actions .action--comment svg path {
        fill: #fff; }
    .album-review__sheet-actions .action--finish {
      color: #fff;
      background-color: #0078D7;
      position: absolute;
      right: 0; }
      .album-review__sheet-actions .action--finish:hover {
        background-color: #0086f1; }
  .album-review__sheet-actions--approved .action--approve {
    cursor: default;
    background-color: #05b563;
    color: #fff; }
    .album-review__sheet-actions--approved .action--approve svg path {
      fill: #fff; }
      .album-review__sheet-actions--approved .action--approve svg path:last-child {
        fill: #05b563; }
    .album-review__sheet-actions--approved .action--approve:hover, .album-review__sheet-actions--approved .action--approve:focus, .album-review__sheet-actions--approved .action--approve:active {
      outline: 0;
      background-color: #05b563;
      color: #fff; }
  .album-review__sheet-actions--commented .action--comment {
    background-color: #D78200; }
