.album-review__sheet-list {
  width: 100%;
  white-space: nowrap;
  margin: auto;
  height: 100%;
  display: block;
  text-align: center;
  position: relative;
  padding: 0px 30px; }
  .album-review__sheet-list .change {
    position: absolute;
    display: block;
    background-color: #242424;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    width: 30px;
    border: 0;
    height: 100%;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    cursor: pointer;
    z-index: 100;
    margin: 0;
    color: #eee;
    outline: 0; }
    .album-review__sheet-list .change:hover {
      background-color: #2c2c2c; }
    .album-review__sheet-list .change--next {
      right: 0;
      box-shadow: -10px 0px 10px #242424; }
    .album-review__sheet-list .change--previous {
      left: 0;
      box-shadow: 10px 0px 10px #242424; }
  .album-review__sheet-list ul {
    width: 100%;
    display: block;
    height: 100%;
    overflow: hidden;
    padding: 0 10px;
    margin: 0; }
    .album-review__sheet-list ul .item {
      position: relative;
      display: inline-block;
      background-color: #242424;
      min-width: 30px;
      padding: 10px 15px 0 15px;
      height: 40px;
      vertical-align: middle;
      font-size: 14px;
      cursor: pointer;
      text-align: center;
      opacity: 0.7;
      margin: 0;
      color: #fff;
      border-bottom: #ccc 3px solid;
      transition: all 0.4s ease; }
      .album-review__sheet-list ul .item--approved {
        color: #05c46b;
        border-bottom: #05c46b 2px solid;
        animation: appearA 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28); }
      .album-review__sheet-list ul .item--commented {
        color: #e67e22;
        border-bottom: #e67e22 2px solid;
        animation: appearB 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28); }
      .album-review__sheet-list ul .item:hover {
        opacity: 0.9; }
      .album-review__sheet-list ul .item--current {
        cursor: default;
        opacity: 1;
        border-bottom-width: 5px;
        font-weight: 600;
        background-color: #3d3d3d; }

@keyframes appearA {
  0% {
    color: #fff;
    z-index: 600;
    background-color: #05c46b;
    opacity: 1;
    border-radius: 100%;
    transform: scale(1.1); }
  50% {
    z-index: 600;
    opacity: 1;
    color: #fff;
    background-color: #05c46b;
    border-radius: 100%;
    transform: scale(1.5); }
  100% {
    border-radius: 0;
    opacity: 1;
    transform: scale(1); } }

@keyframes appearB {
  0% {
    z-index: 600;
    color: #fff;
    background-color: #e67e22;
    opacity: 1;
    border-radius: 100%;
    transform: scale(1.1); }
  50% {
    z-index: 600;
    color: #fff;
    background-color: #e67e22;
    border-radius: 100%;
    transform: scale(1.5); }
  100% {
    border-radius: 0;
    opacity: 1;
    transform: scale(1); } }
