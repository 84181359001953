body,
html {
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  size: landscape; }

* {
  box-sizing: border-box; }

.summary {
  width: 100%;
  max-width: calc(100vw - 100px);
  margin: auto;
  position: relative; }
  .summary .sheet {
    position: relative;
    width: calc(33.3% - 15px);
    margin: 10px 7.5px;
    display: block;
    float: left;
    border: #eee solid 1px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075);
    border-radius: 3px;
    overflow: hidden;
    padding: 0; }
    .summary .sheet .image {
      width: 100%;
      height: 200px;
      text-align: center;
      float: left;
      background-color: #eee;
      background-repeat: repeat;
      display: flex;
      align-items: center;
      justify-content: center; }
      .summary .sheet .image img {
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%; }
    .summary .sheet .reviews {
      width: 100%;
      display: block;
      float: right; }
      .summary .sheet .reviews__details {
        width: 100%;
        clear: both;
        position: relative; }
        .summary .sheet .reviews__details h4 {
          position: relative;
          height: 20px;
          line-height: 20px;
          vertical-align: middle;
          font-weight: 500;
          font-size: 16px;
          padding: 0 15px;
          padding-top: 10px;
          margin: 0; }
        .summary .sheet .reviews__details ul {
          padding: 0;
          width: 100%;
          margin: 0;
          width: 100%;
          overflow: hidden; }
          .summary .sheet .reviews__details ul li {
            width: 100%;
            display: inline-block;
            clear: both;
            padding: 7px 15px 7px 15px;
            border-bottom: 1px solid #eee; }
            .summary .sheet .reviews__details ul li span {
              font-size: 13px;
              display: inline-block; }
              .summary .sheet .reviews__details ul li span:first-child {
                font-weight: 600; }
              .summary .sheet .reviews__details ul li span:last-child {
                padding-left: 10px; }
              .summary .sheet .reviews__details ul li span.status--state0, .summary .sheet .reviews__details ul li span.status--state1, .summary .sheet .reviews__details ul li span.status--state2 {
                color: #566566;
                font-weight: 600; }
              .summary .sheet .reviews__details ul li span.status--state1 {
                color: #e67e22; }
              .summary .sheet .reviews__details ul li span.status--state2 {
                color: #05c46b; }
      .summary .sheet .reviews__item {
        font-size: 14px;
        width: 100%;
        height: auto;
        display: block;
        padding: 0;
        background-color: #e7e7e7;
        min-height: 60px;
        color: #4e4e4e; }
        .summary .sheet .reviews__item__header {
          display: block;
          position: relative;
          width: 100%; }
          .summary .sheet .reviews__item__header span {
            display: inline-block;
            padding: 5px;
            font-weight: 600; }
            .summary .sheet .reviews__item__header span:nth-child(2) {
              padding: 5px 0;
              font-weight: 400; }
            .summary .sheet .reviews__item__header span:last-child {
              float: right; }
        .summary .sheet .reviews__item p {
          border-radius: 3px;
          background-color: #efefef;
          margin: 0;
          padding: 5px;
          font-size: 14px; }

@page {
  size: landscape;
  margin: 10%; }

@media print {
  #app {
    position: relative !important;
    height: auto; }
  div {
    page-break-inside: avoid; } }
